const environments = {
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  API_URL: process.env.REACT_APP_API_URL,
  PRIVY_APP_ID: process.env.REACT_APP_PRIVY_APP_ID,
  NETWORK_ID: process.env.REACT_APP_NETWORK_ID,
  GAME_VERSION: process.env.REACT_APP_GAME_VERSION,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  LAYER_1_NETWORK_ID: process.env.REACT_APP_LAYER_1_NETWORK_ID,
  DEPOSIT_LAYER_1_ADDRESS: process.env.REACT_APP_DEPOSIT_LAYER_1_ADDRESS,
};

export default environments;
